.aviso {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 470px;
    background-color:"#FFF";
}

.autorizacao {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
}

.autorizacao label {
    margin-top: 15px;
    font-weight: bold;

}
.aviso h2 {
    font-weight: bold;
    color: aquamarine;
    
}