.idade {
  width: 30%;
}

.periodo{
  display: block
}

.data0 {
  width: 40%;
}

.data1 {
  width: 40%;
}

.data2 {
  width: 40%;
}


.telefone {
  width: 60%;
}

.email {
  width: 60%;
}

.img {
  width: 20%;
  height: 20%;
  margin-left: 300px;
  margin-top: 20px;
}

.whatsapp {
  width: 7%;
  height: 7%;
  margin-left: 30px;
  margin-top: -90px;
}


.form .periodo .section .control-group .control-label {
    display: block;
    width: 100%;
    margin-bottom: 4px;
}


@media(max-width: 576px) {
    .img {
      width: 30%;
      height: 25%;
      margin-left: 10px;
      margin-top: 4px;
    }
    
  
    .email {
      width: 100%;

    }
  
    .data0 {
      width: 60%;
    }
    
    .data1 {
      width: 60%;
    }
    
    .data2 {
      width: 60%;
    }

    #botao {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    #botao button{
      width: 100%;
      height: 45px;
      margin-top: 10px;
    }

}

  .image{
    margin-left: 1em;
    margin-bottom: 1em;
    border-radius: 50%;
    object-fit: cover;
  }

  .label-avatar{
    width: 280px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  
  }
  
  .label-avatar input{
    display: none;
  }
  
  .label-avatar span{
    z-index: 99;
    position: absolute;
    opacity: 0.7;
    transition: all .5s;
  }
  
  .label-avatar span:hover{
    opacity: 1;
    transform: scale(1.4);
  }

  .label-avatar2{
    width: 120px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .label-avatar2 input{
    display: none;
  }
  
  .label-avatar2 span{
    z-index: 99;
    position: absolute;
    opacity: 0.7;
    transition: all .5s;
  }
  
  .label-avatar2 span:hover{
    opacity: 1;
    transform: scale(1.4);
  }

  #botao label {
    margin-left: 10px;
    font-size: medium;
    font-weight: bold;
  }

