.buttonSend {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonSend button {
  margin-right: 10px;
  padding: 10px;
}

.buttonSend button image {
  margin-right: 10px;
}

.modalQrcode {
    position: fixed;
    top: 0;
    bottom:0;
    right: 0;
    left: 0;
    background: rgba(0,0,0, 0.6);
    z-index: 99;

  }
  
  .modalQrcode .containerQrcode {
    display: block;
    position: fixed;
    max-width: 600px;
    max-height: 550px;
    top: 15%;
    left:0;
    right: 0;
    margin: 0 auto;
    padding: 4em 2rem;
    background-color: #FFF;
    box-shadow: 0 0 20px rgba(0,0,0, 0.8);
    overflow: auto
  }
  
  .closeQrcode{
    background-color: #F65835;
    border:0;
    color: #FFF;
    position: absolute;
    top: 15px;
    left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 15px;
    border-radius: 5px;
  }
  
  .closeQrcode svg{
    margin-right: 5px;
  }
  
  .modalQrcode h2{
    margin-bottom: 1.2em;
    font-size: 2em;
  }
  
  .modalQrcode span{
    font-weight: bold;
    font-size: 1.2em;
    color: #121212;
  }
  
  .modalQrcode span i{
    font-weight: 400;
    margin-right: 1em;
    padding: 2px 8px;
    border-radius: 3px;
  }
  
  .row1{
    margin-bottom: 1em;
  }
  
  .modalQrcode p{
    padding-top: 0.5em;
    white-space: pre-wrap;
  }