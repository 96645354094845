aside.logo {

    display: flex;
    justify-content: center;
    align-items: center;
}

aside.logo img {
    padding:  0 15px;
    width: 108%;

}

@media(max-width: 576px){
    aside.logo img {

        margin-left: 20px;
        width: 150px;
        margin-top: 10px;
    }

    aside.logo {

        height: 50px;
    }

}