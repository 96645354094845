table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

.bloco label {
 margin-left: 25px;
 font-size: 25px;
 font-weight: bold;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}
input {
margin-left: 5px;
}

.nome-prof {
width: 70%;
}

.cref {
width: 40%;
}

#botao {
margin-left: 5px;
}



@media screen and (max-width: 768px){
.nome-prof {
  width: 80%;
}

.cref {
  width: 40%
}

  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}

@media only screen and (max-width:570px) {
.nome-prof {
  width: 90%;
}

.cref {
  width: 40%
}
}