tr .expansive {
  width: '100%';
}


.form select {
  width: 100%;
}

@media screen and (min-width: 769px) {
  table .expansive {
    background-color: #333;
    width: 900px;
  }


  .form {
    width: 100%;
  }

  #expansive {
    width: 900px;
  }
table {
  width: 100%;
}

  .body {
    display: flex;
    flex-direction: row;
    width: max-content;
    justify-content: center;
    background-color: white;
    align-items: center;
   
  }

  table tr  {
    
   
  }
}