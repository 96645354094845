
.menu-area {
    background-color: var(--bg-dark);
    box-shadow: 
    2px 0 10px 0 rgb(0, 0, 0, 0.12),
    2px 0 15px 0 rgb(0, 0, 0, 0.09) ;
}

.menu a {
    background: var(--bg-dark);
}

#menu-desktop a {
    display: block;
    text-decoration: none;
    color: #FFF;
    font-weight: 200;
    padding: 10px;
    margin-left: -45px;
}

.menu a:hover {
    background: linear-gradient(135deg, #135819 0%, #53f561 100%);
}



#mobile {
    display: none;
}

/*configuração para telas grandes */
@media only screen and (min-width:800px) {

    .menu .active {
        background: linear-gradient(135deg, #135819 0%, #53f561 100%);    
    }

    .menu-area .menu .menu-movel {
        margin-top: -800px;
    }

    nav {
        width: 100%;
        height: 50px;
        line-height: 30px;
        background-color: #FFF;
    }

    #mobile {
        display: none;
    }

}

/*Configurações para Tablet */
@media only screen and (max-width: 769px){
    #mobile {
        display: none;
    }

    .menu {
        justify-content: left;
     }

     #menu-div ul{
        margin-left: -20px;
        margin-top: 12px;
        justify-content:left;
        display: flex;
     }

    #menu-desktop a{
        background: linear-gradient(135deg, #135819 0%, #53f561 100%);

        line-height: 10px;
        justify-content:left;
        margin: 2px;
     }

}

/*Configurações para celular */
@media only screen and (max-width:576px) {
    #menu-desktop {
        display: none;
    }

    #menu-desktop {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 90px;
        left: -300px;
        width: 200px;
        margin-left: -400px;
    }

    .menu-area {
        background-color: rgb(9, 82, 9);
        box-shadow: 
        2px 0 10px 0 rgb(0, 0, 0, 0.12),
        2px 0 15px 0 rgb(0, 0, 0, 0.09) ;
    }

    #menu-desktop.active {
        display:inline-block;
        text-decoration: none;
        flex-direction: column;
        position: absolute;
        margin-left: 15px;
        margin-top: -19px;
        left: 0px;
    }
    
    #menu-desktop .active a {
        line-height: 20px;
        margin: 2px;
    }


    #mobile {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    
    #mobile i {
        display: block;
   
        margin-top: 11px;
        margin-left: 12px;
        color: white;
        font-size: 30px;
    }

    .menu a {
        background-color: #FFF;
    }
    
    .menu a:hover {
        background: #E8E8E8;
    }
 
}